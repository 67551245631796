var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "search_result"
  }, [_c('div', {
    staticClass: "top"
  }, [_c('div', {
    staticClass: "title",
    on: {
      "click": _vm.backClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('other.homePage')) + " ")]), _vm._m(0), _c('div', {
    staticClass: "sub_title"
  }, [_vm._v(_vm._s(_vm.$t('title.hotRecommand')))])]), _c('div', {
    staticClass: "all_house"
  }, _vm._l(_vm.recommendList, function (item, index) {
    return _c('houseItem2', {
      key: index,
      attrs: {
        "houseItem2": item
      }
    });
  }), 1), _c('div', {
    staticClass: "pagination"
  }, [_c('el-pagination', {
    attrs: {
      "background": "",
      "layout": "prev, pager, next,sizes",
      "total": _vm.total,
      "current-page": _vm.currentPage,
      "page-size": _vm.pageSize,
      "popper-class": "select_bottom",
      "page-sizes": _vm.pageSizes
    },
    on: {
      "current-change": _vm.currentChangeClick,
      "size-change": _vm.sizeChangeClick
    }
  })], 1), _vm.loadingValue == true ? _c('img', {
    staticClass: "loadingImg",
    attrs: {
      "src": require("../../../assets/loading.gif")
    }
  }) : _vm._e()]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "img"
  }, [_c('img', {
    attrs: {
      "src": require("../../../assets/home_icons/front.png")
    }
  })]);
}];
export { render, staticRenderFns };