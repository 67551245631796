var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('div', {
    staticClass: "house_item",
    style: {
      'backgroundColor': _vm.houseItem2.is_background == 2 ? 'rgba(255, 216, 0, 0.29)' : '#ffffff'
    },
    on: {
      "click": _vm.toDetail
    }
  }, [_vm.houseItem2.is_hot == 2 ? _c('div', {
    staticClass: "collect"
  }, [_c('img', {
    attrs: {
      "src": require("../assets/icons/important.png")
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "house_img"
  }, [_c('img', {
    attrs: {
      "src": _vm.houseItem2.thumb[0]
    }
  })]), _c('div', {
    staticClass: "house_title"
  }, [_vm._v(" " + _vm._s(_vm.houseItem2.title) + " ")]), _c('div', {
    staticClass: "house_price"
  }, [_c('div', {
    staticClass: "house_price_left"
  }, [_vm.houseItem2.price != '0.00' ? _c('div', [_vm._v(" $" + _vm._s(_vm.houseItem2.price) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "house_price_right"
  }, [_vm._v(" " + _vm._s(_vm.houseItem2.create_time) + " ")])])])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };