import houseItem2 from '@/components/houseItem2.vue';
export default {
  components: {
    houseItem2
  },
  data() {
    return {
      recommendList: [],
      backgrounds: true,
      total: 8,
      //总条数
      currentPage: 1,
      //当前页数
      pageSize: 16,
      //每页的条数
      pageSizes: [16],
      loadingValue: false,
      pagesType: ''
    };
  },
  mounted() {
    this.getRecommand();
  },
  // beforeRouteLeave(to, from, next){

  // 	if(this.currentPage>1){
  // 	//    --this.currentPage;
  // 	   console.log(--this.currentPage)
  // 	}
  //     if(this.pagesType==this.currentPage||this.currentPage==1||this.currentPage<1){
  // 		// next();
  // 		this.$router.go(1);
  // 		// console.log(from.name)
  // 		// return false;
  // 	}else{
  // 		console.log(this.currentPage)
  // 	}
  // },
  methods: {
    getRecommand() {
      this.loadingValue = true;
      // this.pagesType=this.$route.query.pages;
      this.$http.getRecommend({
        pageNo: this.currentPage,
        pageSize: this.pageSize,
        is_index: 0,
        type: 1,
        title: this.title
      }).then(res => {
        this.loadingValue = false;
        window.scrollTo(0, 0);
        if (res.code == 1) {
          this.total = res.data.total;
          this.recommendList = res.data.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    backClick() {
      this.$router.go(-1);
    },
    currentChangeClick(currentPage) {
      this.currentPage = currentPage;
      this.getRecommand();
    },
    sizeChangeClick(pageSize) {
      this.pageSize = pageSize;
      this.getRecommand();
    }
  }
};