import "core-js/modules/es.array.push.js";
export default {
  props: ['houseItem2'],
  data() {
    return {};
  },
  methods: {
    toDetail() {
      this.$router.push({
        path: '/detail',
        query: {
          information_id: this.houseItem2.id
        }
      });
      // const path = this.$router.resolve({
      // 	path: '/home/detail',
      // 	query: {
      // 		information_id: this.houseItem2.id,
      // 	}
      // })
      // window.open(path.href, '_blank')
    }
  }
};